
document.addEventListener('DOMContentLoaded', function() {
    // Trova il link con la classe showlogin
    var loginLink = document.querySelector('.woocommerce-error .showlogin');

    // Verifica se il link è presente e modifica l'href
    if (loginLink) {
        loginLink.href = '/accedi/'; // Sostituisci con l'URL desiderato
    }
});
