
import './timeline.js';
import './custom-login-link.js';
import './menu.js';

(function () {
    "use strict";

    $(document).ready(function () {

        // Navbar fixed scroll up
        var previousScroll = 0;
        $(window).scroll(function() {
            var currentScroll = $(this).scrollTop();
            
            // Verifica se l'utente sta scorrendo verso l'alto
            if (currentScroll < previousScroll) {
            // Calcola la percentuale di scroll verso l'alto
            var scrollPercentage = (currentScroll / previousScroll) * 100;
            
            // Mostra la navbar fissa se la percentuale di scroll verso l'alto è maggiore di zero
            if (scrollPercentage > 0) {
                $('.site-header').addClass('fixed');
            } else {
                // Rimuovi la classe "fixed" se la percentuale di scroll verso l'alto è zero
                $('.site-header').removeClass('fixed');
            }
            } else {
            // Nascondi la navbar fissa se l'utente sta scorrendo verso il basso
            $('.site-header').removeClass('fixed');
            }
            
            previousScroll = currentScroll;
        });

        //Cambio colore header scroll

        const isScrolling = () => {
            const headerEl = document.querySelector('.site-header')
            let windowPosition = window.scrollY > 80
            headerEl.classList.toggle('header-scroll', windowPosition)
        }
        window.addEventListener('scroll', isScrolling)

        
        
        // Cambio colore header apertura megamenu pagine generiche

        $('.nav-link').on('click', function() {
            // Verifica se l'elemento con classe .megamenu ha anche la classe .show dopo il click
            if ($('.megamenu').hasClass('show')) {
                // Rimuove la classe .header-trasparent dall'elemento dell'header
                $('.site-header.no-bg').removeClass('header-transparent');
            } else {
                // Aggiungi la classe .header-trasparent all'elemento dell'header se la classe .show è assente
                $('.site-header.no-bg').addClass('header-transparent');
            }
         });

        // Cambio colore header apertura megamenu PAGINA SHOP

        $('.nav-link').on('click', function() {
            // Verifica se l'elemento con classe .megamenu ha anche la classe .show dopo il click
            if ($('.megamenu').hasClass('show')) {
                // Rimuove la classe .header-trasparent dall'elemento dell'header
                $('.post-type-archive-product .site-header').removeClass('shop-header-transparent');
                
                
            } else {
                // Aggiungi la classe .header-trasparent all'elemento dell'header se la classe .show è assente
                $('.post-type-archive-product .site-header').addClass('shop-header-transparent');
            }
         });

   
        // Mobile menu
        $(".menu-toggle").on("click",function(){
            $(".menu-mobile").addClass("menu-mobile-open");
            $('body').addClass("position-fixed");
        });
        $(".menu-mobile-close").on("click",function(){
            $(".menu-mobile").removeClass("menu-mobile-open");
             $('body').removeClass("position-fixed");
        });
   

         // Dropdown mobile menu //

        $(".depth_1 .nav-expand").on("click", function () {
            $(this).children(".depth_2").slideToggle();
        });

        // Dropdown mini cart

        $(".toggle-cart").on("click", function () {
            $('.dropdown-menu-mini-cart').toggleClass('open');
        });

        // Slider Hero Blog

        $('.hero-blog-slider').slick({
            dots: false,
            arrows:false,
        });

         // Slider Filter blog
           $('.alm-filter-nav').slick({
            dots: false,
            arrows:false,
            slidesToShow:1,
            slidesToScroll:1,
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 550,
                    settings: {
                        slidesToShow:2,
                    }
                },
                {
                breakpoint: 660,
                settings: 'unslick',
            }]
        });


        // Slider Block ACF section-btn
           $('.slider-btn').slick({
            dots: false,
            arrows:false,
            slidesToShow:2,
            slidesToScroll:2,
            variableWidth: true,
            mobileFirst: true,
            responsive: [
                {
                breakpoint: 420,
                settings: 'unslick',
            }]
        });

        // Slider Hero sedi

        $('.hero-sedi-slider').slick({
            dots: true,
            arrows:false,
            autoplay:false,
            autoplaySpeed: 2000,
        });



        // Slider Block ACF section-match

        $('.slider-match').slick({
            arrows:false,
            slidesToShow: 1,
            slidesToScroll: 1,
            mobileFirst: true,
            responsive: [
            {
            breakpoint: 700,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
            },
            {
            breakpoint: 1024,
            settings: 'unslick',
            },
           ]
        });

        // Slider Block ACF section-posts

        $('.posts-slider').slick({
            arrows:false,
            dots:false,
            prevArrow: '',
            slidesToShow: 1,
            slidesToScroll: 1,
            mobileFirst:true,
            responsive: [
            {
            breakpoint: 500,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
            },
            {
            breakpoint: 1023,
            settings: {
                arrows:true,
                dots:true,
                slidesToShow: 2,
                slidesToScroll: 2,
            }
            },
            {
            breakpoint: 1199,
            settings: {
                arrows:true,
                dots:true,
                slidesToShow: 3,
                slidesToScroll: 3,
            }
            },

        ]
        });

     // Slider Block ACF hero-shop-slider

        var sliderTimer = 5000;
        var $imageSlider = $('.slider-home-shop');

        $imageSlider.slick({
            autoplay: true,
            autoplaySpeed: sliderTimer,
            speed: 1000,
            arrows: true,
            dots: false,
            adaptiveHeight: true,
            pauseOnFocus: false,
            pauseOnHover: false,
        });

        function progressBar(){
            $('.slider-progress').find('span').removeAttr('style');
            $('.slider-progress').find('span').removeClass('active');
            setTimeout(function(){
                $('.slider-progress').find('span').css('transition-duration', (sliderTimer/1000)+'s').addClass('active');
            }, 100);
	    }
        progressBar();
        $imageSlider.on('beforeChange', function(e, slick) {
            progressBar();
        });

        // Slide Counter Block ACF hero-shop-slider

        var $status = $('.slide-counter');
        // var $slickElement = $('.slideshow');

        $imageSlider.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
        //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
        var i = (currentSlide ? currentSlide : 0) + 1;
        $status.text(i + '|' + slick.slideCount);
        });

    
        // Slider Block ACF section-slider

        $('.slider-info').slick({
            dots: true,
        });

        // Slider Block ACF tax-products and slider products

        $('.slider-tax-product, .slider-products').slick({
            rows: 0,
            arrows: false,
            dots: false,
            mobileFirst: true,
            slidesToShow: 1,
            responsive: [
                	{
				breakpoint: 600,
				settings: {
					slidesToShow: 2
				}
			    },
                {
                    breakpoint: 850,
                    settings: 'unslick',
                }
            ]
        });

          // Slider Block ACF section-slider-products

        $('.slider-all-products').slick({
            arrows:false,
            dots:false,
            prevArrow: '',
            slidesToShow: 1,
            slidesToScroll: 1,
            mobileFirst:true,
            responsive: [
            {
            breakpoint: 500,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
            },
            {
            breakpoint: 799,
            settings: {
                arrows:false,
                dots:true,
                slidesToShow: 3,
                slidesToScroll: 2,
            }
            },
            {
            breakpoint: 1199,
            settings: {
                arrows:false,
                dots:true,
                slidesToShow: 4,
                slidesToScroll: 4,
            }
            },

        ]
        });

        // Slider Block ACF section-gallery

        $('.gallery-img').slick({
            dots: false,
            arrows: false,
        });

        // TAB FAQ

        $('.tab-faq-content').hide();
        $('.tab-faq-content:first').show(); 

        $('ul.tabs-faq li').click(function() {
            $('ul.tabs-faq li').removeClass('active');
            $(this).addClass('active');

            $('.tab-faq-content').hide();
            var activeTab = $(this).attr('rel'); 
            $('#'+activeTab).fadeIn(); 
        });

        //Accordion
        var acc = document.getElementsByClassName("accordion");
        var i;

        for (i = 0; i < acc.length; i++) {
            acc[i].addEventListener("click", function () {
                this.classList.toggle("accordion-active");
                var panel = this.nextElementSibling;
                if (panel.style.maxHeight) {
                    panel.style.maxHeight = null;
                } else {
                    panel.style.maxHeight = panel.scrollHeight + "px";
                }
            });
        };

        // Slider Tab Faq

           $('.slider-tab-faq').slick({
            dots: false,
            arrows:false,
            slidesToShow:1,
            slidesToScroll:1,
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow:2,
                    }
                },
                {
                breakpoint: 1024,
                settings: 'unslick',
            }]
        });

         // Slider Block ACF section-timeline

        $('.slider-timeline').slick({
            dots: false,
            arrows: false,
            slidesToShow:1,
            slidesToScroll:1,
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 599,
                    settings: {
                        slidesToShow:2,
                    }
                },
                {
                breakpoint: 1023,
                settings: {
                    slideToshow:1,
                }
            }]
        });

        // Slider Block ACF section-museum-info

        $('.slider-info-museum').slick({
            dots: false,
            arrows: false,
            slidesToShow:1,
            slidesToScroll:1,
            mobileFirst: true,
            responsive: [
                  {
                    breakpoint: 450,
                    settings: {
                        slidesToShow:2,
                        slidesToScroll:2,
                    }
                },
                {
                breakpoint: 656,
                settings: 'unslick',
            }]
        });

        // slider block ACF setion palmares style two

           $('.slider-palmares').slick({
            dots: false,
            arrows: false,
            slidesToShow:1,
            slidesToScroll:1,
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 599,
                    settings: {
                        slidesToShow:2,
                    }
                },
                {
                breakpoint: 1024,
                settings: 'unslick',
            }]
        });

        // slider block ACF section icon 

           $('.slider-icon').slick({
            dots: false,
            arrows: false,
            slidesToShow:2,
            slidesToScroll:1,
            mobileFirst: true,
            responsive: [
                  {
                    breakpoint: 500,
                    settings: {
                        slidesToShow:3,
                    }
                },
                {
                breakpoint: 600,
                settings: 'unslick',
            }]
        });


        // WC

        // Slider Related products woocommerce

        $('.related .products').slick({
            rows: 0,
            arrows: false,
            dots: false,
            mobileFirst: true,
            slidesToShow: 1,
            responsive: [
                	{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
				}
			    },
                {
                    breakpoint: 850,
                    settings: 'unslick',
                }
            ]
        });

        // Slider btn My Account

           $('.woocommerce-MyAccount-navigation ul').slick({
            dots: false,
            arrows:false,
            slidesToShow:1,
            slidesToScroll:1,
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow:2,
                    }
                },
                {
                breakpoint: 1024,
                settings: 'unslick',
            }]
        });

    
       

        /*
        // Non attivo prima release Ajax Load More (Filtering on click)
        
        function filterClick(event) {
            event.preventDefault();
        
            // Exit if button active.
            if (this.classList.contains('active')) {
                return;
            }
        
            // Get .active element.
            var activeEl = document.querySelector('.alm-filter-nav button.active');
            if (activeEl) {
                activeEl.classList.remove('active');
            }
        
            // Add active class.
            this.classList.add('active');
        
            // Set filter params
            var transition = 'fade';
            var speed = 250;
            var data = this.dataset;
        
            // Call core Ajax Load More `filter` function.
            // @see https://connekthq.com/plugins/ajax-load-more/docs/public-functions/#filter
            ajaxloadmore.filter(transition, speed, data);
        }
        
        // Get all filter buttons.
        var filter_buttons = document.querySelectorAll('.alm-filter-nav button');
        if (filter_buttons) {
            // Set initial active item.
            filter_buttons[0].classList.add('active');
        
            // Loop buttons.
            [].forEach.call(filter_buttons, function (button) {
                // Add button click event.
                button.addEventListener('click', filterClick);
            });
        };

        */


        
    }); // close documet get ready

    

})(jQuery);



