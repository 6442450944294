import $ from "jquery";
import { Dropdown } from './dropdown';

(function($, window, document) {


    // Main Menu
    var genoa_Menu = function () {

        var self = this;

        this.menu_init = function () {
            // Evita chiusura menu al click sopra
            $('.dropdown-menu').click(function(event){
                event.stopPropagation();
            });

            $('.dropdown.has-megamenu').on({
                mouseenter: function () {
                    $('.site-header.no-bg').removeClass('header-transparent');
                },
                mouseleave: function () {
                    $(this).removeClass('show');
                    $(this).children('a').removeClass('show');
                    $(this).find('.dropdown-menu.depth_0').removeClass('show');
                    $('.site-header.no-bg').addClass('header-transparent');
                }
            });
           
            
            // Mobile menu (multilevel)
            var navExpand = [].slice.call(document.querySelectorAll('.nav-expand'))
            var backLink = `<li class="nav-item nav-back-item">
            <a class="nav-link nav-back-link p-4" href="javascript:;">
                Indietro
            </a>
            </li>`

            navExpand.forEach(item => {
            item.querySelector('.nav-expand-content').insertAdjacentHTML('afterbegin', backLink)
            item.querySelector('.nav-link').addEventListener('click', () => item.classList.add('active'))
            item.querySelector('.nav-back-link').addEventListener('click', () => item.classList.remove('active'))
            });

        
        };

        this.bind = function () {
            $( document ).ready( self.menu_init );			
        };
      
        this.init = function () {	
            self.bind();
        };
      
        this.init();

    };

    (new genoa_Menu());

   


}(window.jQuery, window, document));